<template>
  <div>
    <div class="Top_box">
      <div
        class="_SelectHeader"
        style="padding-top: 15px; padding-bottom: 20px"
      >
        <div class="_Select">
          <span style="width: 60px">公司：</span>
          <el-select
            v-model="company"
            placeholder="请选择公司"
            @change="changeCompy"
          >
            <el-option
              v-for="item in companyList"
              :key="item.value"
              :value="item.id"
              :label="item.companyName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">部门：</span>
          <el-select
            placeholder="请选择部门"
            class="select"
            v-model="deptValue"
            @change="changeDept"
            clearable
          >
            <el-option
              v-for="item in deptList"
              :key="item.value"
              :value="item.id"
              :label="item.deptmentName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select">
          <span style="width: 60px">职能：</span>
          <el-select
            placeholder="请选择职能"
            class="select"
            v-model="funCtionValue"
            collapse-tags
            multiple
          >
            <el-option
              v-for="item in functionList"
              :key="item.value"
              :value="item.id"
              :label="item.functionName"
            >
            </el-option>
          </el-select>
        </div>
        <div class="_Select" style="margin-left: 20px">
          <span style="width: 100px">审核类型：</span>
          <el-select
            v-model="typeBtnIndex"
            @change="changeTypeBtn"
            placeholder="请选择"
          >
            <el-option
              v-for="(item, index) in ['内审', '外审']"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </div>
        <el-button icon="el-icon-search"></el-button>
        <div class="statistics">
          <div class="statistics-btn">
            <span @click="toStatistics">查看统计表</span>
          </div>
        </div>
      </div>
      <!-- <div class="box_">
        <div
          v-for="(item, index) in ['内审', '外审']"
          :key="index"
          class="_item"
          :class="{
            action: typeBtnIndex == index,
          }"
          @click="changeTypeBtn(index)"
        >
          {{ item }}
        </div>
      </div> -->
      <!-- <div class="select-box">
        <div class="year">
          <span>年份</span>
          <el-date-picker
            v-model="year"
            format="yyyy"
            value-format="yyyy"
            type="year"
            placeholder="选择年"
          >
          </el-date-picker>
        </div>
        <div style="display: flex; align-items: center; justify-items: center">
          <img
            src="../../assets/images/Examine_img/comp.png"
            style="width: 15px"
            alt=""
          />
          <el-select
            placeholder="请选择公司"
            class="select"
            v-model="company"
            @change="changeCompy"
          >
            <el-option
              v-for="item in companyList"
              :key="item.value"
              :value="item.id"
              :label="item.companyName"
            >
              {{ item.companyName }}
            </el-option>
          </el-select>
        </div>
        <el-select
          placeholder="请选择部门"
          class="select"
          v-model="deptValue"
          @change="changeDept"
          clearable
        >
          <el-option
            v-for="item in deptList"
            :key="item.value"
            :value="item.id"
            :label="item.deptmentName"
          >
            {{ item.deptmentName }}
          </el-option>
        </el-select>
        <el-select
          placeholder="请选择职能"
          class="select"
          v-model="funCtionValue"
          multiple
        >
          <el-option
            v-for="item in functionList"
            :key="item.value"
            :value="item.id"
            :label="item.functionName"
          >
            {{ item.functionName }}
          </el-option>
        </el-select>
      </div> -->
    </div>
    <div class="mid_box">
      <el-button type="primary" class="edit" @click="drawQuestions"
        >抽取题目<i class="el-icon-circle-plus-outline el-icon--right"></i
      ></el-button>
      <img
        style="cursor: pointer; margin: 0 10px"
        src="../../assets/images/Examine_img/del.png"
        alt=""
        @click="delItem"
      />
      <el-checkbox
        label="已完成"
        @change="bindCheckBox()"
        v-model="status"
        true-label="2"
      ></el-checkbox>
      <el-checkbox
        label=""
        @change="bindCheckBox()"
        v-model="status"
        true-label="4"
        >未完成</el-checkbox
      >
      <el-checkbox
        label="有问题点"
        @change="bindCheckBox()"
        v-model="status"
        true-label="5"
      ></el-checkbox>
    </div>
    <div class="_TableBox" :style="{ height: elementHeight + 70 } + 'px'">
      <el-table
        :data="tableData.slice(start, end)"
        :height="elementHeight"
        style="width: 100%"
        highlight-current-row
        :row-class-name="tableRowClassName"
        :row-style="selectedstyle"
        @row-click="rowClick"
        @row-dblclick="rowDblclick"
        :header-cell-style="{
          background: '#EDF0F7',
          color: '#000000',
        }"
      >
        <el-table-column type="selection" width="50">
          <template slot-scope="scope">
            <el-checkbox v-model="scope.row.checked"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column label="类型" prop="auditType" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.auditType == 0">内审</span>
            <span v-if="scope.row.auditType == 1">外审</span>
          </template>
        </el-table-column>

        <el-table-column
          label="抽取问题数"
          prop="titleCount"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column
          prop="failCount"
          label="问题点数"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column prop="status" label="检查状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <span class="status" v-show="scope.row.status === 0">
              <div class="statusbox"></div>
              未抽题
            </span>
            <span class="status" v-show="scope.row.status === 1">
              <div style="background: #7fc7fa" class="statusbox"></div>
              已抽题
            </span>
            <span class="status" v-show="scope.row.status === 2"
              ><div class="statusbox1"></div>
              已自纠</span
            >
            <span class="status" v-show="scope.row.status === 3"
              ><div class="statusbox"></div>
              整改完成</span
            >
            <span class="status" v-show="scope.row.status === 4"
              ><div style="background: #f2729c" class="statusbox"></div>
              自纠中</span
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="completeTime"
          label="完成时间"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <span>{{ scope.row.completeTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="saveTime" label="创建时间" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.saveTime | time }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="saveUserName"
          label="创建人"
          show-overflow-tooltip
        >
        </el-table-column>
      </el-table>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="10"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <!-- <Dialog
      title="抽取题目"
      :showDialog="showDialog"
      width="28%"
      :showTowText="true"
      @handleClose="handleClose"
      @handleReqData="drawQuestions"
    >
      <div class="dialog-contain">
        <div class="box">
          <span style="padding-left: 20px">时间</span>
          <div>
            <el-checkbox
              label="近一年"
              v-model="recentYears"
              true-label="1"
              @change="changRecentYears('1')"
            ></el-checkbox>
            <el-checkbox
              label="近两年"
              v-model="recentYears"
              true-label="2"
              @change="changRecentYears('2')"
            ></el-checkbox>
            <el-checkbox
              label="近三年"
              v-model="recentYears"
              true-label="3"
              @change="changRecentYears('3')"
            ></el-checkbox>
          </div>
        </div>
        <div class="box">
          <span style="padding-left: 20px">抽题目数</span>
          <div class="inp">
            <span>共 {{ this.drawnNumber2 }} 道题，抽取题目数</span
            ><span class="btn" @click="handleDrawnNumber(0)">―</span
            ><input type="text" v-model="drawnNumber" /><span
              class="btn"
              @click="handleDrawnNumber(1)"
              >+</span
            >
          </div>
        </div>
      </div>
    </Dialog> -->
  </div>
</template>

<script>
import { get, post, deleteMethod } from "../../api/http";
// import Dialog from '../../components/dialog/Dialog.vue'

export default {
  // components: { Dialog },
  data() {
    return {
      tabItem: {},
      company: "",
      companyList: [],
      deptList: [],
      deptValue: "",
      funCtionValue: [],
      functionList: [],
      typeBtnIndex: 0, //内审外审
      type: "",
      status: "4", //检查状态
      ischeck: false,
      tableData: [],
      tableRowIndex: null,
      start: 0,
      end: 10,
      curSize: 10,
      showDialog: false, //抽题
      pageSize: 10,
      currentPage: null,
      year: new Date().getFullYear() + "",
      recentYears: "1",
      drawnNumber: 0,
      drawnNumber2: 0,
      elementHeight: 0,
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  beforeRouteEnter(to, from, next) {
    if (from.name != "QuestionDetail") {
      to.meta.ifDoFresh = true;
    }
    next();
  },
  activated() {
    if (this.$route.meta.ifDoFresh) {
      this.$route.meta.ifDoFresh = false;
      this.companyList = this.$store.state.companyUserList;
      this.company = this.$store.state.userInfo.upCompanyId;
      this.getDeptList();
      this.getTableList(false);
    } else {
      this.getTableList(false);
    }
  },
  mounted() {
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 90 + 90);
      });
    },
    getDeptList() {
      get("/api/Department/GetNoTree?CompId=" + this.company).then((resp) => {
        if (resp.code == 200) {
          this.deptValue = "";
          this.deptList = resp.data;
        }
      });
    },
    getFunction() {
      get(
        "/api/InOutAudit/GetFileFunction?CompId=" +
          this.company +
          "&DeptId=" +
          this.deptValue +
          "&Year=" +
          this.year +
          "&AuditType=" +
          this.typeBtnIndex
      ).then((resp) => {
        if (resp.code == 200) {
          this.functionList = resp.data;
        }
      });
    },
    getTableList(goIn) {
      var url1 =
        "/api/InOutAudit/GetByInOutAudit?CompId=" +
        String(this.company) +
        "&AuditType=" +
        this.typeBtnIndex +
        "&Status=" +
        Number(this.status);
      var url2 =
        "/api/InOutAudit/GetByInOutAudit?CompId=" +
        String(this.company) +
        "&AuditType=" +
        this.typeBtnIndex;
      var url;
      if (this.status == 5) {
        url = url2;
      } else {
        url = Number(this.status) && !goIn ? url1 : url2;
      }
      get(url).then((resp) => {
        this.tableData = [];
        if (resp.code == 200) {
          if (goIn) {
            this.status = "4";
            this.rowDblclick(resp.data[0]);
          } else {
            if (this.status == 5) {
              resp.data.map((item) => {
                if (item.failCount > 0) this.tableData.push(item);
              });
              return;
            }
            this.tableData = resp.data;
          }
        }
      });
    },
    bindCheckBox() {
      if (this.status == undefined) {
        this.status = 0;
      }
      this.getTableList(false);
    },
    changeCompy() {
      this.funCtionValue = [];
      this.getDeptList();
      this.getFunction();
      this.getTableList(false);
    },
    changeDept() {
      this.funCtionValue = [];
      this.getFunction();
    },
    changeTypeBtn(i) {
      this.typeBtnIndex = i;
      this.getFunction();
      this.getTableList(false);
    },
    rowClick(row) {
      this.tableRowIndex = row.index;
      this.tabItem = row;
      this.tableData.map((item) => {
        item.checked = false;
      });
      row.checked = true;
    },
    rowDblclick(row) {
      console.log(row);
      var company = this.companyList.filter((i) => i.id == row.companyId);
      this.$router.push(
        "./question-detail?InOutAuditId=" +
          row.id +
          "&CompId=" +
          this.company +
          "&typeBtnIndex=" +
          this.typeBtnIndex +
          "&companyName=" +
          company[0].companyName +
          "&Years=" +
          this.year +
          "&saveTime=" +
          row.saveTime +
          "&saveUserName=" +
          row.saveUserName
      );
    },
    tableRowClassName({ row, rowIndex }) {
      row.index = rowIndex;
    },
    selectedstyle({ rowIndex }) {
      if (this.tableRowIndex == rowIndex) {
        return {
          "background-color": "#E2E8FA !important",
          "font-weight": "bold !important",
          color: "#555555",
        };
      }
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    // 抽题
    changRecentYears(i) {
      this.$router.push("./question-detail");

      if (this.tableRowIndex == null) return;
      this.recentYears = i;
      this.getDrawnNumber();
    },
    handleClose() {
      this.showDialog = false;
    },
    // handleShow() {
    //   this.showDialog = true
    //   this.getDrawnNumber()
    // },
    // // 获取抽题数
    // getDrawnNumber() {
    //   get(
    //     '/api/InOutAudit/GetTitleList?CompId=' +
    //       this.company +
    //       '&AuditType=' +
    //       this.typeBtnIndex +
    //       '&Years=' +
    //       Number(this.recentYears)
    //   ).then((resp) => {
    //     if (resp.code == 200) {
    //       this.drawnNumber = this.drawnNumber2 = resp.data.questionCount
    //     }
    //   })
    // },
    // handleDrawnNumber(i) {
    //   if (this.drawnNumber2 == 0) {
    //     this.$message({
    //       type: 'warning',
    //       message: '暂无抽题数',
    //     })
    //     return
    //   }
    //   if (
    //     (this.drawnNumber == 0 && i == 0) ||
    //     (this.drawnNumber == this.drawnNumber2 && i)
    //   ) {
    //     this.$message({
    //       type: 'warning',
    //       message: '已超出范围',
    //     })
    //     return
    //   }
    //   this.drawnNumber = i == 1 ? this.drawnNumber + 1 : this.drawnNumber - 1
    // },
    drawQuestions() {
      var company = this.companyList.filter((i) => i.id == this.company);
      if (this.funCtionValue.length != 0) {
        var funIdList = [];
        this.funCtionValue.map((item) => {
          funIdList.push({ FunctionId: item });
        });
      }
      var data = {
        jsonData: {
          data: JSON.stringify(funIdList),
        },
        inOutAudit: {
          id: 0,
          companyId: this.company + "",
          companyName: company[0].companyName,
          years: this.year,
          auditType: Number(this.typeBtnIndex),
          remark: "",
          chargeUserId: this.$store.state.userInfo.id + "",
          planId: "",
          status: Number(this.status),
          saveTime: new Date().toISOString(),
          completeTime: new Date().toISOString(),
          saveUserId: this.$store.state.userInfo.id + "",
          saveUserNo: this.$store.state.userInfo.userNo,
          saveUserName: this.$store.state.userInfo.userName,
        },
      };
      if (this.funCtionValue.length == 0) delete data.jsonData;
      post("/api/InOutAudit/InsertInOutAudit?DeptId=" + this.deptValue, data)
        .then((resp) => {
          if (resp.code == 200) {
            this.getTableList(true);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("抽题失败");
        });
    },
    delItem() {
      this.$confirm("此操作将永久删除该内外审检查？", "是否继续?", "提示")
        .then(() => {
          deleteMethod("/api/InOutAudit?Id=" + Number(this.tabItem.id))
            .then((resp) => {
              if (resp.code == 200) {
                this.tableData.splice(this.tableRowIndex, 1);
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.tableRowIndex = null;
              } else {
                this.$message.error(resp.message);
              }
            })
            .catch(() => {
              this.$message.error("删除失败");
            });
        })
        .catch(() => {});
    },
    toStatistics() {
      this.$router.push("/Statistics");
    },
  },
};
</script>
<style>
/* .el-table__body tr.current-row > td {
  background: #d4e5f9 !important;
} */
</style>
<style lang="less" scoped>
.Top_box {
  position: relative;
  background: none;
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: -10px;
  .select-box {
    display: flex;
    align-items: center;
    margin-top: 15px;
  }
}
.statistics {
  position: absolute;
  right: 0px;
  .statistics-btn {
    width: 170px;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../assets/images/Examine_img/statisticsBtn.png");
    background-size: 100%;

    span {
      margin-top: 5px;
      margin-left: 45px;
      font-size: 14px;
      color: white;
    }
  }
}

.statusbox {
  margin: 5px;
  width: 10px;
  height: 10px;
}
.statusbox1 {
  margin: 5px;
  width: 10px;
  height: 10px;
  background: url(../../assets/images/Examine_img/ok.png);
  background-size: 100%;
}
.Noaction {
  opacity: 0.3;
}
option {
  background: #ffffff;
  line-height: 20px;
  border: 0px;
}
.mid_box {
  background: #ffffff;
  padding: 4px;
  margin-bottom: 0px;
  border-bottom: 1px solid #dcdcdc;
}

.status {
  display: flex;
  align-items: center;
  justify-content: center;
}
.box_ {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow: hidden;
  margin-top: 15px;
}
.action {
  background: #5b79ae !important;
  color: #ffffff !important;
}
._item {
  width: 105px;
  height: 40px;
  color: #000000;
  background: #ffffff;
  border: 1px solid #e7e9ec;
  box-shadow: 0px 2px 8px 0px rgba(160, 164, 170, 0.36);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  cursor: pointer;
}
.dialog-contain .box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
  /deep/ .el-checkbox__label {
    font-size: 12px !important;
  }
  div {
    width: 300px;
    text-align: center;
    input {
      width: 40px;
      border: 1px solid;
      height: 20px;
      border-color: #969696;
      text-align: center;
    }
  }
  .inp {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      width: 30px;
      height: 25px;
      line-height: 25px;
      font-size: 16px;
      display: inline-block;
      background-color: rgb(238, 237, 237);
      color: blue;
      margin: 0 4px;
    }
  }
}
/deep/ .el-select__tags {
  top: 0 !important;
  transform: none !important;
}
.year {
  margin: 0 15px;
  /deep/ & .el-date-editor.el-input,
  /deep/ & .el-date-editor {
    width: 100px !important;
  }
  /deep/ & .el-input__inner {
    background: none !important;
    border: none !important;
  }
}

@import "../../assets/style/Examine_CSS.css";
</style>
